.NavbarItems{
    background:rgb(0, 0, 0);
    height: 80px;
    display:flex ;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
}

.navbar-logo{
    color:#ffff;
    justify-self:start;
    margin-left: 20px;
    cursor: pointer;
}

.fa-react{
    margin-left: 0.5rem;
    font-size: 1.6rem;
}

.nav-menu{
    display:grid;
    grid-template-columns: repeat(5,auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: end;
    margin-right:2rem;
}

.nav-links{
    color:white;
    text-decoration: none;
    padding: 0.5rem 1rem;

}

.nav-links:hover{
    background-color: #91ff00;
    border-radius: 4px;
    transition: all 0.2 ease-out;

}
.fa-bars{
    color:#ffff;
    font-size:2rem;
    

}

.nav-links-mobile{
    display: none;
}

.menu-icon{
    display:none;
}

@media screen and (max-width:960px){
    .NavbarItems{
        position:relative;
    }

    .nav-menu{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 500px;
        position: absolute;
        top:80px;
        left:-100%;
        opacity:1;
        transition:all 0.5s ease;

    }

    .nav-menu.active{
        background: #86e706;
        left:0;
        opacity: 1;
        transition: 0.5s ease;
        z-index: 1;
    }

    .nav-links{
        color: #fff;
        text-align: center;
        padding: 0.5rem 1rem;
        width: 100%;
        display: table;
    }

   
    .navbar-logo{
        position: absolute;
        top: 0;
        left:0;
        transform: translate(254,50%);
    }
    .menu-icon{
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100,60%);
        cursor:pointer;

    }

    .fa-times{
        color:#fff;
        font-size:2rem;
    }

    .nav-links-mobile{
        display: block;
        text-align: center;
        padding: 1.5rem;
        border-radius: 4px;
        margin: 2rem auto;
        width: 80%;
        background: #4ad9e4;
        text-decoration: none;
        color: #fff;
        font-size: 1.5rem;
    }
    
    .nav-links:hover{
        background: #fff;
        color: #656BF4;
        transition: 250ms;

    }
    Button{
        display:none;
    }
}