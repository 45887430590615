.background {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
  }

  .p{
    text-align: center;
  }
  
  .hero-container {
    background: url('images/img3.jpg') center center/cover no-repeat;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.2);
  }
  
  .hero-container > h1 {
    color: #ffff;
    font-size: 80px;
    margin-top: 30px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  }
  
  .hero-container > p {
    margin-top: 8px;
    color: #ffff;
    font-size: 26px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    margin-left: 10px;
    margin-right: 10px;
  }
  
  .hero-container > h2 {
    color: #ffff;
    font-size: 50px;
    margin-top: 10px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  }
  
  @media screen and (max-width: 960px) {
    .hero-container > h1 {
      font-size: 50px;
      margin-top: 50px;
    }
    .hero-container > h2 {
      font-size: 30px;
      margin-top: 50px;
    }
  }
  
  @media screen and (max-width: 760px) {

    .hero-container > h1 {
      font-size: 30px;
      margin-top: 25px;
    }
    .hero-container > p {
      font-size: 15px;
    }
    .hero-container > h2 {
      font-size: 25px;
      margin-top: 20px;
    }
  }
  