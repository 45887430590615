.banner-section {
  text-align: center;
  padding: 60px 0;
  color:white;
  
}

.banner-image {

  max-width: 100%;
  height: auto;
  object-fit: cover;
}

body {
  background: linear-gradient(to right, #0f2027, #203a43, #6dbfe2);
  height: 100vh;
  margin: 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.content {
  margin-top: 0px;
}




/* Media query for laptop screens */
@media (min-width: 1024px) {
  .banner-section {
    font-size: 30px; /* Increase the font size for laptops */
  }
}
