*{
  box-sizing:border-box;
  margin: 0;
  padding: 0;
  font-family: 'Courier New', Courier, monospace;
}

/* Submit button styles */
input[type="submit"] {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

input[type="submit"]:hover {
  background-color: #0056b3;
}
.submit {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.submit:hover {
  background-color: #0056b3;
}






input[type="submit"] {
  display: block;
  padding: 6px 30px;
  font-size: 14px;
  background-color: #4460AA;
  color: #fff;
  border: none
}

.item-bx{
  background: #151515;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -37px;
  margin-bottom: -36px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Add a shadow to .item-bx */
  border-radius: 40px; /* Add border radius for a softer look */
}

.item-bx .p{
  color:#808080;
}

.background-image-frame {

  position:relative;
  width: 100%;
  z-index: -30;
  display: none;
  margin-left: -60px;
}

.background-image-image {
  object-fit: cover;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  overflow:hidden;
  display: block;
}

@media screen and (max-width: 768px) {
  .background-image-frame {
      display: block;
  }

  .background-image-image {
      display: none;
  }
}



